export default {
	cinema: {
		address: 'ул. Банковская д. 9',
		place: null,
		phoneNumbers: ['8 (931) 382-82-83'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinoslantcy',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://itunes.apple.com/ru/app/кинотеатр-террикон/id1387397732?ls=1&mt=8',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.terrikon',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ua/maps/-/CBqWnOQLdC',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
